document.addEventListener('DOMContentLoaded', () => {
	const carousels = [... document.querySelectorAll('.carousel')];
	for (const carousel of carousels) {
		carousel.addEventListener('slide.bs.carousel', function(e){
			const newIdx = e.to;
			const indicators = [... carousel.querySelectorAll('.carousel-controls .indicator')];
			const newIndicator = indicators[newIdx];
			for (const indicator of indicators) {
				indicator.classList.remove('active');
			}
			newIndicator.classList.add('active');
		});
	}
});
