document.addEventListener('DOMContentLoaded', () => {
	const menuItems = document.querySelectorAll('header .menu a[data-bs-target]');
  function closeMegamenus() {
    const visibleMenus = [...document.querySelectorAll('.mega-menu-collapse.show')];
    for (const visibleMenu of visibleMenus) {
      const cm = bootstrap.Collapse.getInstance(visibleMenu);
      cm.hide();
    }
  }
	for (const menuItem of [...menuItems]) {
		const collapse = document.querySelector(menuItem.getAttribute('data-bs-target'));
		collapse.addEventListener('show.bs.collapse', function () {
			closeMegamenus();
			menuItem.parentElement.classList.add('active');
			document.body.classList.add('megamenu-open');
			this.classList.add('opening');
			setTimeout(() => {
				this.classList.remove('opening');
			}, 500);
		});
		collapse.addEventListener('hide.bs.collapse', function () {
			menuItem.parentElement.classList.remove('active');
			document.body.classList.remove('megamenu-open');
		});
	}
  const mainSearchButton = document.querySelector('.col-desktop-search .btn');
  mainSearchButton.addEventListener('click', e => {
    if ( document.body.classList.contains('megamenu-open') ) {
      e.preventDefault();
      document.body.classList.remove('megamenu-open');
      closeMegamenus();
      setTimeout(() => {
        const searchInput = mainSearchButton.previousElementSibling;
        searchInput.focus();
        searchInput.selectionStart = searchInput.selectionEnd = searchInput.value.length;
      }, 250);
    }
  });
  document.body.addEventListener('click', e => {
    if (e.target === document.body && document.body.classList.contains('megamenu-open')) {
      document.body.classList.remove('megamenu-open');
      closeMegamenus();
    }
  });
});
