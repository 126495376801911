document.addEventListener('DOMContentLoaded', () => {
	let storyHeader;
	let storyHeaderContainer;

	function initStoryMode() {
		storyHeaderContainer = null;
		storyHeader = document.querySelector('section.story-hero');
		if (storyHeader) {
			storyHeaderContainer = storyHeader.querySelector('.container');
			window.addEventListener('resize', resizeStoryHeader);
			resizeStoryHeader();
			hookStoryCloseButton();
			const storyTeaser = storyHeader.querySelector('.story-teaser');
			const transparentClone = storyTeaser.cloneNode(true);
			transparentClone.classList.add('clone');
			storyTeaser.after(transparentClone);
		}
	}
	initStoryMode();

	function resizeStoryHeader() {
		storyHeader.style.setProperty('--story-content-height', storyHeaderContainer.offsetHeight + 'px');
		if (window.innerHeight < storyHeaderContainer.offsetHeight) {
			storyHeader.classList.add('non-sticky');
		} else {
			storyHeader.classList.remove('non-sticky');
		}
	}

	function hookStoryCloseButton() {
		document.querySelector('.story-close').addEventListener('click', function(e){
			if (this.closest('#story-wrapper')) {
				e.preventDefault();
				hideWrapper();
			}
		});
	}

	function getPopupWrapper() {
		const wrapper = document.getElementById('story-wrapper');
		if (wrapper) {
			return wrapper;
		}
		const wrapperElem = document.createElement('div');
		wrapperElem.id = 'story-wrapper';
		document.body.appendChild(wrapperElem);
		const wrapperLoader = document.createElement('div');
		wrapperLoader.classList.add('loader');
		wrapperElem.appendChild(wrapperLoader);
		const wrapperContent = document.createElement('div');
		wrapperContent.classList.add('content');
		wrapperElem.appendChild(wrapperContent);
		return wrapperElem;
	}

	function showWrapper() {
		const wrapper = getPopupWrapper();
		wrapper.classList.remove('d-none');
		document.body.classList.add('story-open');
	}

	function showWrapperLoading() {
		const wrapper = getPopupWrapper();
		wrapper.classList.add('loading');
		wrapper.querySelector('.content').innerHTML = '';
	}

	function hideWrapper() {
		const wrapper = getPopupWrapper();
		wrapper.classList.add('d-none');
		document.body.classList.remove('story-open');
		wrapper.querySelector('.content').innerHTML = '';
	}

	function showStoryPopup(url) {
		showWrapper();
		showWrapperLoading();
		const wrapper = getPopupWrapper();
		fetch(url).then(async response => {
			const storyHtml = await response.text();
			const parser = new DOMParser();
			const doc = parser.parseFromString(storyHtml, 'text/html');
			const wrapperContent = wrapper.querySelector('.content');
			wrapperContent.innerHTML = doc.querySelector('main').innerHTML;
			wrapper.classList.remove('loading');
			initStoryMode();
			resizeStoryHeader();
		}).catch(err => {
			console.error(err);
			// TODO error handling
		});
	}

	document.body.addEventListener('click', function(e){
		let storyLink = e.target.closest('.story-link');
		if (storyLink) {
			e.preventDefault();
			const storyUrl = storyLink.getAttribute('href');
			showStoryPopup(storyUrl);
		}
	});
});
