document.addEventListener('DOMContentLoaded', () => {               
    const posters = document.querySelectorAll('section.video-text a');
    for (const poster of posters) {
        poster.addEventListener('click', e => {
            const id = poster.getAttribute("data-video");
            if (id) {
                const video = document.getElementById("video_" + id );
                if (video) {
                    video.classList.remove("d-none");
                    poster.classList.add("d-none");
                    video.play();
                } 
            }                                                  
        }); 
    }                       
});    