document.addEventListener('DOMContentLoaded', () => {
	const mobileMenu = document.getElementById('norturaMenu');
	if (mobileMenu) {
		mobileMenu.addEventListener('show.bs.collapse', e => {
			if (e.target === mobileMenu) {
				document.body.classList.add('mobile-menu-open');
			}
		});
		mobileMenu.addEventListener('hidden.bs.collapse', e => {
			if (e.target === mobileMenu) {
				document.body.classList.remove('mobile-menu-open');
			}
		});

		const mobileMenuDropdowns = mobileMenu.querySelectorAll('.submenu-wrapper');
		for (const mobileMenuDropdown of mobileMenuDropdowns) {
			mobileMenuDropdown.addEventListener('show.bs.collapse', function(){
				for (const mmd of mobileMenuDropdowns) {
					if (mmd !== this) {
						const collapseInstance = bootstrap.Collapse.getInstance(mmd);
						if (collapseInstance) {
							collapseInstance.hide();
						}
						// bootstrap.Dropdown.getOrCreateInstance(mmd).hide();
					}
				}
			});
		}
	}
});
