document.addEventListener('DOMContentLoaded', () => {
    const shortendListItems = document.querySelectorAll('li.breadcrumb-item.shortend');
    const hiddenListItems = document.querySelectorAll('li.breadcrumb-item.d-none');
    for (const shortendListItem of [...shortendListItems]) {
        shortendListItem.addEventListener('click', e => {
            e.preventDefault();
            shortendListItem.classList.add('d-none');
            for (const hiddenListItem of hiddenListItems) {
                hiddenListItem.classList.remove('d-none');
            }
        });
    }
});