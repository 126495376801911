document.addEventListener('DOMContentLoaded', () => {
	const filterSelect = document.getElementById('places-filter');

	if (filterSelect) {
		filterSelect.addEventListener('change', function(){
			const value = this.value;
			const countyWrappers = this.closest('section').querySelectorAll('.county-wrapper');

			// show all
			for (const countyWrapper of countyWrappers) {
				countyWrapper.classList.remove('d-none');
			}

			// hide what is not needed
			if (value) {
				for (const countyWrapper of countyWrappers) {
					if (countyWrapper.dataset.county !== value) {
						countyWrapper.classList.add('d-none');
					}
				}
			}
		});
	}
});
