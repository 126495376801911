document.addEventListener('DOMContentLoaded', () => {
	const tabButtonsWrapper = document.querySelector('ul.tabs');
	if (tabButtonsWrapper) {
		const tabButtons = [...tabButtonsWrapper.querySelectorAll('a.btn')];
		const tabContents = [...tabButtonsWrapper.parentElement.querySelectorAll('.tab-content')];
		for (const tabButton of tabButtons) {
			tabButton.addEventListener('click', function(){
				this.classList.add('btn-success');
				this.classList.remove('btn-alt');
				for (const tabButton of tabButtons) {
					if (tabButton !== this) {
						tabButton.classList.add('btn-alt');
						tabButton.classList.remove('btn-success');
					}
				}
				const matchingTabContent = tabContents.find(tc => tc.dataset.id === this.dataset.id);
				if (matchingTabContent) {
					matchingTabContent.classList.remove('d-none');
					for (const tabContent of tabContents) {
						if (tabContent !== matchingTabContent) {
							tabContent.classList.add('d-none');
						}
					}
				}
			});
		}
	}
});
